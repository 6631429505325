<template>
    <div style="background-color: #3A68CD;min-height: 100vh;overflow: scroll;padding-bottom: 40px;">
        <div class="top_bg" style="background-image: url('img/luckyBag/top_bg.png');"></div>
        <div class="play_tips" style="background-image: url('img/luckyBag/play_tips.png');">
            <div class="play_tips_title" style="background-image: url('img/luckyBag/title.png');"></div>
        </div>
        <div class="bronze_bag" style="background-image: url('img/luckyBag/bronze_egg_bg.png');">
            <div class="bronze_title_bg" style="background-image: url('img/luckyBag/bronze_title.png');"></div>
            <div class="gift_box">
                <div v-for="(item, index) in info1" :key="index">
                    <div class="gift_item" style="background-image: url('img/luckyBag/gift_bg.png');">
                        <div class="sub_bg" style="background-image: url('img/luckyBag/sub_bg.png');">
                            <img class="gold" src="img/bi.png" alt="">
                            <span class="value">{{ item.diamonds }}</span>
                        </div>
                        <img class="gift_img" :src="item.gift_url" alt="">
                    </div>
                    <div class="gift_name">{{ item.gift_name }}</div>
                </div>

            </div>
        </div>
        <div class="gold_bag" style="background-image: url('img/luckyBag/bronze_egg_bg.png');">
            <div class="bronze_title_bg" style="background-image: url('img/luckyBag/gold_title.png');"></div>
            <div class="gift_box">
                <div v-for="(item, index) in info2" :key="index">
                    <div class="gift_item" style="background-image: url('img/luckyBag/gift_bg.png');">
                        <div class="sub_bg" style="background-image: url('img/luckyBag/sub_bg.png');">
                            <img class="gold" src="img/bi.png" alt="">
                            <span class="value">{{ item.diamonds }}</span>
                        </div>

                        <img class="gift_img" :src="item.gift_url" alt="">
                    </div>
                    <div class="gift_name">{{ item.gift_name }}</div>
                </div>
            </div>
        </div>
        <div class="diamond_bag" style="background-image: url('img/luckyBag/bronze_egg_bg.png');">
            <div class="bronze_title_bg" style="background-image: url('img/luckyBag/diamond_title.png');"></div>
            <div class="gift_box">
                <div v-for="(item, index) in info3" :key="index">
                    <div class="gift_item" style="background-image: url('img/luckyBag/gift_bg.png');">
                        <div class="sub_bg" style="background-image: url('img/luckyBag/sub_bg.png');">
                            <img class="gold" src="img/bi.png" alt="">
                            <span class="value">{{ item.diamonds }}</span>
                        </div>
                        <img class="gift_img" :src="item.gift_url" alt="">
                    </div>
                    <div class="gift_name">{{ item.gift_name }}</div>
                </div>

            </div>
        </div>
        <div class="special_detail" style="background-image: url('img/luckyBag/special_tips.png');">
            <div class="special_title" style="background-image: url('img/luckyBag/special_title.png');"></div>
        </div>
        <div class="final_detail">
            本活动最终解释权归多乐所有
        </div>
    </div>
</template>
<script>
import "@/assets/css/luckyBag.css"
import { luckyBagInfo } from '@/api/api'
export default {
    data() {
        return {
            info1: [],
            info2: [],
            info3:[]
        }
    },
    created() {
        let access_token = this.$route.query.access_token
        localStorage.setItem('access_token', access_token)
        this.getInfo()
    },
    methods: {
        getInfo() {
            let params = {
                type:1
            }
            luckyBagInfo(params).then(res => {
                this.info1 = res.data[0].gift_info
                this.info2 = res.data[1].gift_info
                this.info3 = res.data[2].gift_info
            })
        }
    }
}
</script>
 